import { CONSTANTS } from "@@addons/constants";
import { LoginStore } from "@@addons/database/dixie/login";
import { OfflinePostStore } from "@@addons/database/dixie/offline_posts";
import { formDataToJson } from "@@addons/functions/form_data_to_object";
import { getValueFromFormDataKey } from "@@addons/functions/form_data_to_object/get_value_by_key";
import { http } from "@@addons/functions/https/http";
import { https_swal_error_format } from "@@addons/functions/https/https_swal_error_format";
import { GetNetworkStatus } from "@@addons/functions/https/network_status";
import { DateTime } from "luxon";
import Swal from "sweetalert2";

export async function POST_PublicLogin(): Promise<any> {

  const url = CONSTANTS.URLS.AKWAABA_API_BASE_URL + 'form-membership/login/',
    formContainer: HTMLFormElement = document.querySelector('[make-general-posts="public-login"]'),
    formData = new FormData(formContainer);

  GetNetworkStatus(online => {
    // console.log({ "networkStatus": online });
    // console.log({ "networkStatus": "we are online" });
    return Swal.fire({
      title: 'Login?',
      // text: "You won't be able to revert this!",
      icon: 'info',
      iconColor: '#f97817',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#f97817',
      cancelButtonColor: '#ddd',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async (login) => {
        return await http(url, {
          method: "POST",
          body: formData,
        }, true, false).then((response: any) => {
          // console.log({ response: response });
          if (response.success) {
            let loginDb = new LoginStore(),
              data = response.data;
            let rememberMe: string | boolean | undefined = getValueFromFormDataKey(
              formData, "remember"
            );
            // console.log({ rememberMe });
            rememberMe = rememberMe === "on" ? true : false;
            // console.log({ rememberMe });
            data['rememberMe'] = rememberMe;
            // console.log({ "response.data": response.data, "firstname": response.data['firstname'] });            
            loginDb.makePost(
              [loginDb.formatRawPublicData(data),],
              (res) => {
                // console.log({ "----res----": res });
                window.location.href = CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL;
              },
            );
          } else {
            try {
              const res = https_swal_error_format(response.msg);
              Swal.showValidationMessage(
                `${res}`
              );
            } catch (error) {
              Swal.showValidationMessage(
                `${String(error)}`
              );
            }
          }

        }).catch(error => {
          Swal.showValidationMessage(
            `${String(error)}`
          );
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Login Successful",
          icon: 'success',
          iconColor: '#f97817',
          confirmButtonColor: '#f97817',
        });
      }
    });
  }, offline => {

    // console.log({ "networkStatus": "we are offline" });
    return Swal.fire({
      title: "Looks like you're offline",
      text: "Do you want to save data offline?",
      icon: 'question',
      iconColor: '#f97817',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#f97817',
      cancelButtonColor: '#ddd',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async (login) => {

        const offlinePost = new OfflinePostStore();
        let post = offlinePost.makePost([
          {
            id: DateTime.now().toMillis(),
            url: url,
            formData: formDataToJson(formData),
            description: "Login",
            date: DateTime.now().toJSDate(),
            networkResponse: "",
          },
        ], (res) => {
          // console.log({ res });

        });
        // console.log({ post });
        return post;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Data has been saved offline pending internet connection!",
          icon: 'info',
          iconColor: '#f97817',
          confirmButtonColor: '#f97817',
        });
      }
    });
  });
}